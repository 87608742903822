import AnggotaBase from "./anggotaBase.js"

const modelName = "pspd";
const modelPath = "/anggota/";

class PSPD extends AnggotaBase {
  constructor(overrideName) {
    let model = {
      npm: "",
      lulus: false,  // sementara, akan dihapus
      angkatan: null, // terkait dengan input component, maka di-init dengan null
    };
    let requiredFields = ["npm", "angkatan"];
    let ovrName = overrideName ? overrideName : modelName;
    super(ovrName, model, requiredFields, [], modelPath);
  }

  initObservables() {
    super.initObservables();
    this.observables.tipe = "pspd";
  }

  getLoadData(data) {
    let tdata = super.getLoadData(data);
    tdata.npm = data.pspd.npm;
    tdata.angkatan = data.pspd.angkatan.id;
    return tdata;
  }
}

export default PSPD;