<template>
  <common-anggota-create-update :headerText="headerText" :anggotaMdl="pspdMdl">
    <b-field
      label="NPM"
      custom-class="is-small"
      :type="{'is-danger': errorMap.npm}"
      :message="errorMap.npm"
    >
      <b-input v-model="anggota.npm" required @input="validateInput('npm')"></b-input>
    </b-field>
    <b-field
      v-if="isCreate"
      label="Angkatan"
      custom-class="is-small"
      :type="{'is-danger': errorMap.angkatan}"
      :message="errorMap.angkatan"
    >
      <generic-select
        v-model="anggota.angkatan"
        apiPath="/programstudi/angkatan/options/"
        @input="validateInput('angkatan')"
      ></generic-select>
    </b-field>
  </common-anggota-create-update>
</template>


<script>
import { has } from "lodash";
import PSPD from "../models/pspd.js";

export default {
  name: "PSPDCreateUpdate",
  props: ["headerText"],
  components: {
    CommonAnggotaCreateUpdate: () =>
      import("@/apps/anggota/views/CommonAnggotaCreateUpdate.vue"),
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue")
  },
  data() {
    this.pspdMdl = new PSPD("anggota");
    return this.pspdMdl.getObservables();
  },
  computed: {
    isCreate() {
      return !has(this.$route.params, "id");
    }
  },
  methods: {
    validateInput(field) {
      this.pspdMdl.validate(field);
    },
  },
};
</script>
